.nav {
    background-color: #002CEF;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 10px 0; 
    box-sizing: border-box; 
  }
  
  .title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 20px; 
    color: #FFF;
    font-family: 'Jomhuria';
    font-size: 75px;
    font-weight: 400;
  }
  
  .links {
    display: flex; 
    flex-direction: column; /* Changed to column to stack the links vertically */
    gap: 10px; /* Adjusted the gap to fit the vertical layout */
  }
  
  .link {
    color: #FFF;
    font-family: 'Rubik';
    font-size: 20px;
    font-weight: 400;
  }
  
  .link span {
    margin-right: 10px; 
  }

  .link a {
    text-decoration: underline; /* Add underline to the links */
    color: inherit; /* Optional: To ensure the links inherit the text color */
  }
  
  /* Mobile styles */
  .link {
    margin-left: 43px;
  }
  
  /* Desktop styles */
  @media (min-width: 768px) { 
    .link {
      margin-left: 94px;
    }
  }
  