@import url('https://fonts.googleapis.com/css2?family=Rubik+One:wght@400&family=Rubik:wght@400&family=Jomhuria:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jomhuria&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Rubik One', 'Rubik', 'Jomhuria', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.claim-pass-button {
  width: 100%;
  height: 44px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: 'Rubik One', sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1px;
  background-color: #002CEE;
  background-clip: border-box;

}



.bg-blue-500{
  --tw-bg-opacity: 0;
}